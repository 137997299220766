export const ProjectList = [
	{
		id: '1',
		name : 'The Fishing Website',
		slug: 'the-fishing-website',
		link : 'http://www.fishing.net.nz/',
		img : 'fishing-site-img.jpg',
		thumb : {
			img: 'fishing_site_thumb.jpg',
		},
		description : 'The Fishing Website sought to enhance its responsive design to improve mobile-friendliness and user experience. In my role as a Front-End Developer, I collaborated with a designer to implement a fully responsive layout built on the Mura CMS platform.',
		client : 'The Fishing Website',
		workfrom : 'Freelance',
		tech : 'HTML5, CSS3, jQuery, JavaScript, .NET, Adobe ColdFusion',
		role : 'Front End Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '2',
		name : 'Covertax Ltd',
		slug: 'covertex',
		link : 'http://www.covertex.co.nz/',
		img : 'covertex-img.jpg',
		thumb : {
			img: 'covertex-thumb.jpg'
		},
		description : 'Covertex aimed to revamp its existing WordPress websites, incorporating blogs, latest news, and video functionalities to enhance SEO performance. As a Full-Stack Web Developer, I was responsible for maintaining the web server, optimizing performance, and ensuring seamless website functionality.',
		client : 'COVERTAX Ltd',
		workfrom : 'Freelance',
		tech : 'HTML5, CSS3, jQuery, JavaScript, PHP, MySQL',
		role : 'Web Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '3',
		name : 'Saint Kentigern',
		slug: 'saint-kentigern',
		link : 'http://www.saintkentigern.com/',
		img : 'saintkentigern-img.jpg',
		thumb : {
			img: 'saintkentigern-thumb.jpg'
		},
		description : 'Saint Kentigern School sought to develop a new responsive CMS website featuring multiple online application forms and a seamlessly integrated donation system with payment gateways. In my role as a Full-Stack Developer, I collaborated with a designer and a third-party team to architect and build the website using the Concrete5 CMS, ensuring an intuitive user experience and streamlined functionality.',
		client : 'Saint Kentigern Collage',
		workfrom : 'Tin Soldier',
		tech : 'HTML5, CSS3,  jQuery, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '4',
		name : 'Tin Soldier',
		slug: 'tin-soldier',
		link : 'https://tinsoldier.co.nz/',
		img : 'tinsoldier-img.jpg',
		thumb : {
			img: 'tinsoldier-thumb.jpg'
		},
		description : 'Tin Soldier aimed to create a newly rebranded website with a modern, minimalist design and a CMS for seamless content management and project showcasing. As a Full-Stack Developer, I collaborated with a designer to develop a fully responsive website using Concrete5 CMS, ensuring an intuitive user experience and streamlined functionality.',
		client : 'Tin Soldier',
		workfrom : 'Tin Soldier',
		tech : 'HTML5, CSS3, LESS, jQuery, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '5',
		name : 'Spark Arena',
		slug: 'spark-arena',
		link : 'https://www.sparkarena.co.nz/',
		img : 'sparkarena-img.jpg',
		thumb : {
			img: 'sparkarena-thumb.jpg'
		},
		description : 'Spark Arena (formerly Vector Arena), sponsored by Spark New Zealand, sought to implement its new brand identity into the existing Vector Arena website with an adaptive, mobile-responsive design. As a Full-Stack Developer, I collaborated with a designer and a backend developer to create a seamless, user-friendly website that effectively showcased information while maintaining ease of navigation and functionality.',
		client : 'Spark Arena',
		workfrom : 'Tin Solider',
		tech : 'HTML5, CSS3, jQuery, JavaScript, Adobe ColdFusion',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[]
	},
	{
		id: '6',
		name : 'Human Rights Commission',
		slug: 'hrc',
		link : 'https://www.hrc.co.nz/',
		img : 'hrc-home.jpg',
		webp: 'hrc-home.webp',
		thumb : {
			img: 'hrc_thumb.jpg'
		},
		description : 'The Human Rights Commission sought to develop a newly rebranded website featuring a comprehensive resource library, accessible complaint forms, and enhanced site accessibility. As a Full-Stack Web Developer, I collaborated with a designer to build a fully responsive website using Concrete5 CMS, ensuring a seamless user experience and efficient content management.',
		client : 'Spark Arena',
		workfrom : 'Tin Solider',
		tech : 'HTML5, CSS3, LESS, jQuery, JavaScript, PHP, MySQL',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'hrc-about.jpg',
				webp: 'hrc-about.webp'
			},
			{
				id: 2,
				img : 'hrc-resources.jpg',
				webp: 'hrc-resources.webp'
			},
		]
	},
	{
		id: '7',
		name : 'Graeme Dingle Foundation',
		slug: 'graeme-dingle-foundation',
		link : 'https://dinglefoundation.org.nz/',
		img : 'dingle-main.jpg',
		webp: 'dingle-main.webp',
		thumb : {
			img: 'dingle-thumb.jpg'
		},
		description : 'Graeme Dingle Foundation, a leading child and youth development charity, sought to create a newly rebranded website with seamless donation functionality and improved accessibility to resources and event news. In my role as a Full-Stack Developer, I collaborated with a designer to develop a user-friendly, responsive website featuring a custom donation system integrated with the Stripe payment gateway, ensuring a smooth and secure donation process.',
		client : 'Graeme Dingle Foundation',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, PHP, MySQL, Stripe API, WooCommerce',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'dingle-donation.jpg',
				webp: 'dingle-donation.webp'
			}
		]
	},
	{
		id: '8',
		name : 'New Zealand Avocado',
		slug: 'new-wealand-avocado',
		link : 'https://www.nzavocado.co.nz/',
		link2: 'https://industry.nzavocado.co.nz/',
		img : 'nzavo-main.jpg',
		webp : 'nzavo-main.webp',
		thumb : {
			img: 'nzavo-thumb.jpg'
		},
		description : 'New Zealand Avocado aimed to rebrand its consumer and industry websites to enhance user engagement and accessibility. The consumer site was designed to showcase recipes, making it easier for users to incorporate avocados into their meals. The industry site featured events, news, and a resource library to streamline information access and improve usability. As a Full-Stack Web Developer, I collaborated with a designer to develop a fully responsive website, integrating OAuth login functionality for secure user authentication.',
		client : 'New Zealand Avocado',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, PHP, MySQL, OAuth',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'nzavo-recipes.jpg',
				webp: 'nzavo-recipes.webp'
			},
			{
				id: 2,
				img : 'nzavo-recipe-detail.jpg',
				webp: 'nzavo-recipe-detail.webp'
			}
		]
	},
	{
		id: '9',
		name : 'Neudorf Wine',
		slug: 'neudorf-wine',
		link : 'https://neudorf.co.nz/',
		img : 'neudorf-main.jpg',
		webp: 'neudorf-main.webp',
		thumb : {
			img: 'neudorf-thumb.jpg',
			webp: 'neudorf-thumb.webp',
		},
		description : 'Neudorf Vineyards sought to redesign its website using the Shopify eCommerce framework, enhancing the user experience with seamlessly navigable wine reviews and tasting notes. As a Full-Stack Web Developer, I collaborated with a freelance designer at Studio Nash to develop a visually refined, user-friendly platform that streamlined content management and product presentation.',
		client : 'Neudorf Wine',
		workfrom : 'Freelance',
		tech : 'HTML5, CSS3, Vue, JavaScript, Shopify',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'neudorf-menu.jpg',
				webp: 'neudorf-menu.webp'
			},
			{
				id: 2,
				img : 'neudorf-wine.jpg',
				webp: 'neudorf-wine.webp'
			},
			{
				id: 3,
				img : 'neudorf-review-filter.jpg',
				webp: 'neudorf-review-filter.webp'
			}
		]
	},
	{
		id: '10',
		name : 'Placemakers Pro APP',
		slug: 'placemakers-pro-app',
		link : '',
		img: 'proapp-main.jpg',
		webp: 'proapp-main.webp',
		thumb : {
			img: 'pro-app-thumb.jpg'
		},
		description : 'PlaceMakers aimed to develop a mobile application enabling users to search for product information, create and manage projects, and seamlessly share project details via email. In my role as a Full-Stack Developer, I collaborated with a designer to build the application, implementing SSO authentication flows and integrating REST APIs for enhanced functionality and user experience.',
		client : 'Placemakers',
		workfrom : 'Terabyte',
		tech : 'Flutter, Rest API, Auth0, SalesForce CRM',
		role : 'Full Stack Developer',
		type : 'App',
		appLinks: [
			{
				type: "Android",
				link: "https://play.google.com/store/apps/details?id=com.fbu.placemakerspro&hl=en_NZ&gl=US"
			},
			{
				type: "iOS",
				link: "https://apps.apple.com/nz/app/placemakers-pro/id1478968534"
			}
		],
		images:[
			{
				id: 1,
				img : 'proapp-1.jpg',
				webp: 'proapp-1.webp'
			},
			{
				id: 2,
				img : 'proapp-2.jpg',
				webp: 'proapp-2.webp'
			},
			{
				id: 3,
				img : 'proapp-3.jpg',
				webp: 'proapp-3.webp'
			},
			{
				id: 4,
				img : 'proapp-4.jpg',
				webp: 'proapp-4.webp'
			},
		]
	},
	{
		id: '11',
		name : 'Keep New Zealand Beautiful',
		slug: 'keep-new-zealand-beautiful',
		link : 'http://knzb.org.nz/',
		img : 'knzb-main.jpg',
		thumb : {
			img: 'knzb-thumb.jpg'
		},
		description : 'KNZB (Keep New Zealand Beautiful) aimed to develop a newly rebranded website with integrated donation and eCommerce functionality while enhancing user accessibility to resources and event news. As a Full-Stack Web Developer, I collaborated with a designer to build a fully responsive website using WordPress and WooCommerce, incorporating a custom event form integration to streamline user engagement and transactions.',
		client : 'Keep New Zealand Beautiful',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, PHP, MySQL, WooCommerce',
		role : 'Full Stack Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'knzb-events.jpg',
				webp: 'knzb-events.webp'
			},
			{
				id: 2,
				img : 'knzb-shop.jpg',
				webp: 'knzb-shop.webp'
			},
		]
	},
	{
		id: '12',
		name : 'Punga Cove',
		slug: 'punga-cove',
		link : 'https://www.pungacove.co.nz/',
		img : 'punga-main.jpg',
		webp: 'punga-main.webp',
		thumb : {
			img: 'punga-thumb.jpg'
		},
		description : 'Punga Cove, a subsidiary of Marlborough Tour Company, sought to develop a newly rebranded website to enhance user experience by making accommodation options and activities easily accessible. As a Front-End Developer, I collaborated with a designer to build a fully responsive website using Umbraco. Additionally, I worked closely with a Back-End Developer to integrate booking information, ensuring a seamless and user-friendly reservation process.',
		client : 'marlboroughtourcompany',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			
			{
				id: 1,
				img : 'punga-accommodation.jpg',
				webp: 'punga-accommodation.webp'
			},
			{
				id: 2,
				img : 'punda-detail.jpg',
				webp: 'punda-detail.webp'
			}
		]
	},
	{
		id: '13',
		name : 'Marlborough Tour Company',
		slug: 'marlborough-tour-company',
		link : 'https://www.marlboroughtourcompany.co.nz/',
		img : 'mtc-main.jpg',
		webp: 'mtc-main.webp',
		thumb : {
			img: 'mtc-thumb.jpg'
		},
		description : 'Marlborough Tour Company sought to develop a newly rebranded website to enhance the user experience by making accommodation options and activities easily accessible. As a Front-End Developer, I collaborated with a designer to build a fully responsive website using Umbraco. Additionally, I worked closely with a Back-End Developer to integrate booking information, ensuring a seamless and user-friendly reservation process',
		client : 'Marlborough Tour Company',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img: 'mtc-detail.jpg',
				webp: 'mtc-detail.webp'
			}
		]
	},
	{
		id: '14',
		name : 'Fusion 5',
		slug: 'fusion-5',
		link : 'https://www.fusion5.co.nz/',
		link2: 'https://www.fusion5.com/au/',
		img : 'fuison5-main.jpg',
		webp: 'fuison5-main.webp',
		thumb : {
			img: 'fusion5-thumb.jpg'
		},
		description : 'usion5 sought to develop newly rebranded websites for its New Zealand and Australian markets, enhancing user experience and making resources easily accessible. I collaborated with a designer and back-end developer to build a fully responsive website using Umbraco, ensuring seamless navigation and optimal functionality across both sites.',
		client : 'Fusion 5',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img: 'fuison5-detail.jpg',
				webp: 'fuison5-detail.webp'
			}
		]
	},
	{
		id: '15',
		name : 'Mico',
		slug: 'mico',
		link : 'https://www.mico.co.nz/',
		img : 'mico-main.jpg',
		webp : 'mico-main.webp',
		thumb : {
			img: 'mico-thumb.jpg'
		},
		description : 'Mico aimed to rebuild its B2C eCommerce website, enhancing user experience by making store details, products, and document downloads easily accessible. Additionally, they sought to implement an admin portal for efficient product information management.As a Front-End Developer, I collaborated with a designer to develop a fully responsive website using BigCommerce. I also worked closely with a Back-End Developer to integrate the admin portal with Umbraco and REST APIs, enabling seamless product management and data accessibility.',
		client : 'Mico',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, BigCommerce, Rest API, Google Map API, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'mico-product-listing.jpg',
				webp: 'mico-product-listing.webp'
			},
			{
				id: 2,
				img : 'mico-store-finder.jpg',
				webp: 'mico-store-finder.webp'
			}
		]
	},
	{
		id: '16',
		name : 'Top 10',
		slug: 'top-10',
		link : 'https://top10.co.nz/',
		img : 'top-10-main.jpg',
		webp : 'top-10-main.webp',
		thumb : {
			img: 'top-10-thumb.jpg'
		},
		description : 'TOP 10 Holiday Parks, one of the largest providers of holiday accommodation bookings, sought to rebrand their website to enhance usability, making it easier for users to search for and book accommodations.As a Front-End Developer, I collaborated with a designer to develop a fully responsive website using Umbraco. Additionally, I worked closely with a Back-End Developer to integrate REST APIs with Vue.js, enabling seamless accommodation search, detailed listings, and an intuitive booking system.',
		client : 'Top 10 Holiday Park',
		workfrom : 'Terabyte',
		tech : 'HTML5, CSS3, JavaScript, Vue, Rest API, Umbraco',
		role : 'Front End Developer',
		type : 'Web site',
		images:[
			{
				id: 1,
				img : 'top-10-list-view.jpg',
				webp: 'top-10-list-view.webp'
			},
			{
				id: 2,
				img : 'top-10-map-view.jpg',
				webp: 'top-10-map-view.webp'
			},
			{
				id: 3,
				img : 'top-10-map.jpg',
				webp: 'top-10-map.webp'
			},
			{
				id: 4,
				img : 'top-10-product.jpg',
				webp: 'top-10-product.webp'
			},
			{
				id: 5,
				img : 'top-10-calendar.jpg',
				webp: 'top-10-calendar.webp'
			},
			{
				id: 6,
				img : 'top-10-add-to-cart.jpg',
				webp: 'top-10-add-to-cart.webp'
			},
			{
				id: 7,
				img : 'top-10-cart.jpg',
				webp: 'top-10-cart.webp'
			}
		]
	}
];