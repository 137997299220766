<template>
	<Header :path="path"/>
	<section class="c-section">
		<div class="c-projects">
			<div class="c-project__sidebar">
				<h2 class="c-section__heading">Selected Works</h2>
			</div>
			<div v-if="projects.length" class="c-projects__list">
				<div v-for="project in projects" :key="project.id" class="c-proejct">
					<router-link :to="{name: 'ProjectDetails', params:{slug: project.slug}}" :project="[project]">
						<figure v-if="project.thumb" class="o-fig o-fig__landscape c-proejct__thumb">
							<picture>
								<source v-if="project.thumb.webp" :srcset="require('../../assets/'+ project.thumb.webp)" type="image/webp">
								<img class="c-project__img o-fig__img" :src="require('../../assets/'+ project.thumb.img)" alt="">
							</picture>
						</figure> 

						<h3 class="c-project__name">{{project.name}}</h3>
						<p class="c-project__role">{{project.role}} role from {{project.workfrom}}</p>
					</router-link>
				</div>
			</div>
		</div>
	</section>
	<Footer />
</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	import {ProjectList} from '@/data/data'

	export default {
		name: 'Work',
		components: {
			Header,
			Footer
		},
		data(){
			return {
				publicPath: process.env.BASE_URL,
				path: this.$route.name,
				projects: ProjectList.sort((a,b) =>  b.id-a.id )
			}
		},
		mounted(){

			const selectedProjects = ProjectList.filter(obj => {
				return obj.workfrom !== ""
			})
			this.projects = selectedProjects;

		}
	}
</script>

<style lang="css" scoped>
</style>