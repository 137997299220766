<template>
  <Header :path="path"/>
  <section class="c-section">
    <div class="c-section__content">
      <h2 class="c-section__heading">About</h2>
        <p>I am a highly experienced Full-Stack Web Developer with over {{totalExperience}} of professional expertise in building responsive and interactive websites. Passionate about delivering high-quality digital solutions, I am committed to staying at the forefront of emerging technologies to drive innovation and enhance user experiences.</p>
        <p>As a dedicated and results-driven professional, I take pride in my strong work ethic, attention to detail, and problem-solving skills. I am continuously researching and adopting the latest industry advancements to optimize development processes, ensuring the best outcomes for both the company and its customers.</p>
        <p>If you want to know more about me? follow me <strong><a href="https://www.linkedin.com/in/billy-han-1a253976/" target="_blank">Linkedin</a></strong> or reach out by <strong><a href="mailto:nada_billy0217@hotmail.com">email</a></strong></p>
        <p><a href="https://github.com/billy0217/" target="_blank" class="c-icon-link"><i class="iconf-github c-icon-link__icon"></i>GitHub Repo</a></p>
        <p><a href="https://codepen.io/billy0217/pens/public" target="_blank" class="c-icon-link"><i class="iconf-codepen c-icon-link__icon"></i>CodePen Play Ground</a></p>
    </div>
  </section>
  <Footer />
</template>

<script>
// @ is an alias to /src
import { ref } from 'vue';
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  setup(){
    const totalExperience = ref(null);

    let df= new Date("6/14/2013");
    let dt = new Date();
    let allMonths= dt.getMonth() - df.getMonth() + (12 * (dt.getFullYear() - df.getFullYear()));
    let allYears= dt.getFullYear() - df.getFullYear();
    let partialMonths = dt.getMonth() - df.getMonth();
    let months;
    if (partialMonths < 0) {
        allYears--;
        partialMonths = partialMonths + 12;
    }

    if( 0 < allYears ){
      allYears += ' years';
    }

    if( 1 <= partialMonths){
      months = " and " + partialMonths + " months"
    }else{
      months = "";
    }

    totalExperience.value = allYears + months;

    return {totalExperience};

  },
  data(){
    return {
      path: this.$route.name,
    }
  }
}
</script>

