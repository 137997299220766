<template>
	<Header :path="path"/>
	<section class="c-section">
		<div v-if="project">
			<div class="c-project__info">

				<div class="c-project__info-wrap">

					<h2 class="c-section__heading c-project__info-name">{{project.name}}</h2>

					<div class="c-project__img-wrap c-project__info-main-img">
						<figure v-if="project.img" class="o-fig o-fig__landscape">
							<picture>
								<source v-if="project.webp" :srcset="require('../../assets/'+ project.webp)" type="image/webp">
								<img class="c-project__img o-fig__img" :src="require('../../assets/'+ project.img)" alt="">
							</picture>
						</figure>
					</div>

					<p class="c-project__info-role">{{project.role}}</p>

					<div class="c-project__info-detail">
						<p>{{project.description}}</p>
						<p><strong>Technologies :</strong> {{project.tech}}</p>
						<p v-if="project.link"><a :href="project.link" target="_blank">{{project.link}}</a></p>
						<p v-if="project.link2"><a :href="project.link2" target="_blank">{{project.link2}}</a></p>
						<div v-if="project.appLinks">
							<p  v-for="app in project.appLinks" :key="app.type">
								<a  :href="app.link" target="_blank">{{app.type}}</a>
							</p>
						</div>
					</div>
					
					<div v-if="project.images.length">
						<div class="c-project__img-wrap" v-for="img in project.images" :key="img.id">
							<figure class="o-fig o-fig__landscape">
								<picture>
									<source v-if="img.webp" :srcset="require('../../assets/'+ img.webp)" type="image/webp">
									<img class="c-project__img o-fig__img" :src="require('../../assets/'+ img.img)" alt="">
								</picture>
							</figure>
						</div>
					</div>

				</div>

				<div class="c-project__back-top-list">
					<router-link :to="{name: 'Work'}" class="c-project__info-back-btn">
						<i class="c-project__info-back-icon iconf-arrow-left2"></i> Back
					</router-link>
				</div>

			</div>
		</div>
	</section>
	<Footer />
</template>

<script>
	// @ is an alias to /src
	import Header from '@/components/Header'
	import Footer from '@/components/Footer'
	import {ProjectList} from '@/data/data'

	export default {
	  	props: ['slug'],
		components: {
			Header,
			Footer
		},
		data(){
			return {
				publicPath: process.env.BASE_URL,
				path: this.$route.name,
				project: null
			}
		},
		mounted(){

			const selectedProject = ProjectList.filter(obj => {
			   return obj.slug === this.slug
			})
			this.project = selectedProject[0];

		}
	}
</script>

<style lang="css" scoped>
</style>